import React from "react";
import SVG_Dot from "../../../../../assets/images/vector/components/common/dots.svg"

const POINTS_CONTENT = {
  SVG_Dot,
  listTexts: [
    "找到最高利润和低竞争产品",
    "来自本地和国际供应商数据库",
    "获取最流行和还没发现的产品关键词",
  ]
}
export default POINTS_CONTENT
