import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import BlackCloseIcon from "../../../../assets/images/vector/components/common/blackClose.svg";
import WhiteCloseIcon from "../../../../assets/images/vector/components/common/close_white.svg";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";


const DealsCN = ({ isShowDeals, clickOpenHandler, isBottomLine }) => {
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);

  return isShowDeals ? (
    <div
      className={getTrueClasses(
        "PgCMain-DealsCN-section",
        "PgCMain-DealsCN-section_fixed",
        isBottomLine && "PgCMain-DealsCN-section_stay"
      )}
      onClick={clickOpenHandler}
    >
      <div className="PgCMain-DealsCN-container">
        <div className="PgCMain-DealsCN-content">
          <button className="PgCMain-DealsCN__close btn_clear deals__closeJS">
            <img
              className="PgCMain-DealsCN__closeJS"
              src={isNotInitMobile ? WhiteCloseIcon : BlackCloseIcon}
              alt="X"
              loading="lazy"
              id="js-close-panel"
            />
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default DealsCN;
