import React, { useEffect, useState } from "react";
import "./StandardEmailCollect/index.scss";
import PropTypes from "prop-types";
import track from "../../../tracking/analytics";
import { BUTTON_BACKGROUND, PAGES } from "../../../helpers/constants";

const RedirectedButton = (props) => {
  const {
    title,
    href,
    isIntro,
    background = BUTTON_BACKGROUND.GRADIENT,
    isTopText = true,
    page,
    customClass,
    isShowText = false,
    isLoading,
    staticTopTitle,
  } = props;

  const [isTablet, setIsTablet] = useState(false);

  const classes = [
    "installView",
    "emailCollect",
    isIntro ? "left" : "",
    page ? `installView-${PAGES[page].toLowerCase()}` : "",
    customClass,
  ]
    .filter(Boolean)
    .join(" ");

  useEffect(() => {
    const handleResize = () =>
      setIsTablet(window.matchMedia("(max-width: 991px)").matches);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isTablet]);
  const urlRedirect =
    typeof href === "object" ? href[isTablet ? "MOBILE" : "DESKTOP"] : href;

  const handleClick = () => {
    track("Index", urlRedirect, "TryForFree", undefined, "LANDING");
  };
  const showTopText = () =>
    isShowText ? (
      isTopText ? (
        <p>Please click the button below if you are not redirected</p>
      ) : null
    ) : null;

  return (
    <div className={classes} style={{ opacity: isLoading ? 0 : 1 }}>
      {staticTopTitle ? <p>{staticTopTitle}</p> : showTopText()}
      <a
        className={`bt-orange bg-${background}`}
        onClick={handleClick}
        rel="nofollow"
        href={urlRedirect}
        target="_blank"
      >
        {title}
      </a>
    </div>
  );
};

RedirectedButton.propTypes = {
  title: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  page: PropTypes.string,
  isIntro: PropTypes.bool,
  background: PropTypes.string,
  isTopText: PropTypes.bool,
  customClass: PropTypes.string,
  isShowText: PropTypes.bool,
  staticTopTitle: PropTypes.string,
};

export default RedirectedButton;
