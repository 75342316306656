import React from "react";
import EmailCollectContainer from "./EmailCollectContainer";
import PropTypes from "prop-types";
import EmailCollectCn from "../../../components/common/Forms/emailCollectCn";

const CnEmailCollectContainer = (props) => {
  const {
    page,
    installViewCustomClass,
    buttonTextSecondState,
    redirectUrl,
    noCreditCardRequired,
    buttonTextFirstState,
    btnMaxContent,
    customClass,
    isNodeTitle,
    title,
    operation,
    type,
    information,
    onSuccess,
    redirect,
    handleClick,
    isCnUser,
  } = props;

  return (
    <EmailCollectContainer
      page={page}
      onSuccess={onSuccess}
      operation={operation}
      customType={type}
      language="cn"
      customRedirectUrl={redirectUrl}
      redirect={redirect}
    >
      <EmailCollectCn
        installViewCustomClass={installViewCustomClass}
        buttonTextSecondState={buttonTextSecondState}
        buttonTextFirstState={buttonTextFirstState}
        btnMaxContent={btnMaxContent}
        page={page}
        customClass={customClass}
        isNodeTitle={isNodeTitle}
        title={title}
        information={information}
        noCreditCardRequired={noCreditCardRequired}
        handleClick={handleClick}
        isCnUser={isCnUser}
      />
    </EmailCollectContainer>
  );
};

CnEmailCollectContainer.propTypes = {
  page: PropTypes.string.isRequired,
  installViewCustomClass: PropTypes.string,
  buttonTextSecondState: PropTypes.string,
  redirectUrl: PropTypes.string,
  buttonTextFirstState: PropTypes.string,
  btnMaxContent: PropTypes.bool,
  customClass: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  operation: PropTypes.string,
  isNewForm: PropTypes.bool,
  type: PropTypes.number,
};

export default CnEmailCollectContainer;
