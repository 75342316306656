import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const SectionFour = ({ handleClick }) => {
  return (
    <section className="PgCMain-SectionFour">
      <div className="PgCMain-SectionFour__container">
        <div className="PgCMain-SectionFour__wrapButtons">
          <div className="PgCMain-SectionFour__wrapBtn">
            <TrackedLink
              category="CNIndex"
              action="WithoutVpn"
              class="PgCMain-SectionFour__btn"
              path="https://microsoftedge.microsoft.com/addons/detail/amazon-product-finder-a/idcpecldgbbabloifgjogemdifohldkd?hl=zh-CN"
              target
            >
              免费试用Edge的AMZ侦探 PRO扩展
            </TrackedLink>
            <p>*100%保证无需翻墙软件即可使用*</p>
          </div>
          <div className="PgCMain-SectionFour__wrapBtn">
            <div className="PgCMain-SectionFour__btn" onClick={handleClick}>
              免费试用Chrome的AMZ侦探 PRO扩展
            </div>
            <p>*仅通过翻墙软件使用*</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
