import React from "react";
import "../ControlsEmailCollect/index.scss";
import PropTypes from "prop-types";

const CnRedirectedButton = ({
  title,
  href,
  classes,
  background,
  handleClick,
}) => {
  return (
    <div className={classes}>
      <a
        className={`bt-orange bg-${background}`}
        onClick={handleClick}
        rel="nofollow"
        href={href}
        target="_blank"
      >
        {title}
      </a>
    </div>
  );
};

CnRedirectedButton.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  background: PropTypes.string,
  customClass: PropTypes.string,
};

export default CnRedirectedButton;
