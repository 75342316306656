import React from "react";
import POINTS_CONTENT from "./index.content";
import "./index.scss";

const IntroPoints = () => {
  const { SVG_Dot, listTexts } = POINTS_CONTENT;

  return (
    <ul className="PgCMain-I-Points">
      {listTexts.map((text, i) => (
        <li key={i}>
          <img src={SVG_Dot} alt="-" />
          {text}
        </li>
      ))}
    </ul>
  );
};

export default IntroPoints;
